@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  min-height: -webkit-fill-available;
}
html {
  height: -webkit-fill-available;
}


/* @media (max-width: 480px) {
  .container {
    width: 100vw !important;    
    border-width: 0px !important;
  }
} */

/* HTML: <div class="loader"></div> */
.loader {
  width: 35px;
  aspect-ratio: 4;
  --_g: no-repeat radial-gradient(circle closest-side,var(--loader-color) 90%,#0000);
  background: 
    var(--_g) 0%   50%,
    var(--_g) 50%  50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 100%;
  animation: l7 1s infinite linear;
}

@keyframes l7 {
    33%{background-size:calc(100%/3) 0%  ,calc(100%/3) 100%,calc(100%/3) 100%}
    50%{background-size:calc(100%/3) 100%,calc(100%/3) 0%  ,calc(100%/3) 100%}
    66%{background-size:calc(100%/3) 100%,calc(100%/3) 100%,calc(100%/3) 0%  }
}

@media (min-width: 480px) {
  .container {
    max-width: 360px !important;
    border-width: 1px !important;
    min-height: 640px;
  }
}

.loading-height{
height: 640px;
}

.white-snackbar{
  --mdc-snackbar-container-color: white !important;
  --mdc-snackbar-supporting-text-color: #2962FF !important;
  --mdc-snackbar-supporting-text-size: 1rem !important;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number]{
  -moz-appearance: textfield;
}

.mdc-notched-outline__notch {
  border-right: 0px !important;
}

asl-google-signin-button {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.dark .otp-input {
  background-color: #050b1c !important;
}

.dark .mat-mdc-select-value {
  color: white !important;
}
